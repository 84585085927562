import { getClients } from "../../loader";

export default {
  namespaced: true,
  state: {
    clients: [],
  },
  mutations: {
    setClients(state, payload) {
      state.clients = payload;
    }
  },
  actions: {
    async loadClients({commit}) {
      let resp = await getClients();
      commit('setClients', resp.data);
    }
  },
  getters: {
    clients(state) {
      return state.clients;
    },
  },
};
