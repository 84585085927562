const REMOTE = 'remote';

const enviroments = {
  temp: {
    BASE_URL: "https://fp.bkp.skloud.com.br",
    BASE_URL_API: "https://fp-api.bkp.skloud.com.br/api",
  },
  remote: {
    BASE_URL: "https://fisiopower.suporteinformatika.com",
    BASE_URL_API: "https://fisiopower-api.suporteinformatika.com/api",
  },
  local: {
    BASE_URL: "http://localhost:8001",
    BASE_URL_API: "http://localhost:8000/api",
  },
};

export const config = enviroments[REMOTE];
