import axios from "axios";
import router from "./router";

import { config } from "./enviroment";

const cfg = axios.create({
  baseURL: config.BASE_URL_API,
});

cfg.interceptors.request.use(
  (config) => {
    const { token } = window.localStorage;
    if (token) {
      config.headers.Authorization = `Berer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

cfg.interceptors.response.use(
  (response) => {
    if (response.headers["x-refresh-token"]) {
      window.localStorage.setItem("token", response.headers["x-refresh-token"]);
    }
    return response;
  },
  (err) => {
    const { response } = err;
    if (!response || response.status === 401) {
      window.localStorage.removeItem("token");
      router.push({ name: "Login" });
    }
    return Promise.reject(err);
  }
);

export default cfg;
