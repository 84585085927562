<template>
  <v-snackbar
    top
    :color="type"
    :value="snackbar"
    @input="hide"
    :timeout="timeout"
    multi-line
  >
    {{ message }}
    <template v-slot:action="{ attr }">
      <v-btn elevation="2" v-bind="attr" icon @click="hide">
        <v-icon> mdi-window-close </v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "SnackBar",
  methods: {
    ...mapActions({ hide: "snackbar/hide" }),
  },
  computed: {
    ...mapGetters({
      message: "snackbar/message",
      snackbar: "snackbar/snackbar",
      timeout: "snackbar/timeout",
      type: "snackbar/type",
    }),
  },
};
</script>

<style scoped></style>
