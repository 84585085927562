import Vue from "vue";
import VuetifyMask from "vuetify-mask";

import Container from "./views/_templates/Container.vue";

import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store/store";

import axios from "./axios";

import vue_moment from "vue-moment";

Vue.prototype.$axios = axios;
Vue.use(vue_moment);
Vue.use(VuetifyMask);

Vue.mixin({
  methods: {
    logout() {
      localStorage.removeItem("token");
      this.$router.push("/login");
    },
  },
});

Vue.filter("money", function (value) {
  return parseFloat(value).toLocaleString("pt-BR", {
    style: "currency",
    currency: "BRL",
  });
});

Vue.config.productionTip = false;

new Vue({
  store,
  vuetify,
  router,
  render: (h) => h(Container),
}).$mount("#app");
