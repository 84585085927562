import Vue from "vue";
import Vuex from "vuex";

import pdv from "./modules/pdv";
import snackbar from "./modules/snackbar";
import client from './modules/client';
import seller from './modules/seller';
import payment from './modules/payment';
import carrier from './modules/carrier';
import product from './modules/product';

Vue.use(Vuex);

export default new Vuex.Store({
  actions:{
    loadAll({dispatch}) {
      dispatch('client/loadClients')
      dispatch('seller/loadSellers')
      dispatch('carrier/loadCarriers')
      dispatch('payment/loadCoins')
      dispatch('product/loadProducts')
      dispatch('loadBox')
    },
  },
  modules: {
    pdv,
    snackbar,
    client,
    seller,
    payment,
    carrier,
    product,
  },
});
