import axios from "../../axios";
import router from "../../router";
import { getBox } from "../../loader";

export default {
  state: {
    request: null,
    products: [],
    devolutionProducts: [],
    seller: null,
    client: null,
    box: null,
    drawer: true,
  },
  mutations: {
    addProduct(state, payload) {
      state.products.push(payload);
    },
    addDevolution(state, payload) {
      state.devolutionProducts.push({ ...payload });
    },
    clearProducts(state) {
      state.products = [];
    },
    minusOneProduct(state, payload) {
      state.products[payload].amount--;
    },
    moreOneProduct(state, payload) {
      state.products[payload].amount++;
    },
    addClient(state, payload) {
      state.client = payload;
    },
    addSeller(state, payload) {
      state.seller = payload;
    },
    setRequest(state, payload) {
      state.request = payload;
    },
    delProduct(state, payload) {
      state.products.splice(payload, 1);
    },
    delClient(state) {
      state.client = null;
    },
    delSeller(state) {
      state.seller = null;
    },
    removeRequest(state) {
      state.request = null;
      state.devolutionProducts = [];
    },
    setBox(state, payload) {
      state.box = payload;
    },
    removeBox(state) {
      state.box = null;
    },
    creditClient(state, payload) {
      const client = { ...state.client };
      state.client = {
        ...client,
        creditInPortfolio:
          Math.round((+client.creditInPortfolio + +payload) * 100) / 100,
      };
    },
    debitClient(state, payload) {
      const client = { ...state.client };
      state.client = {
        ...client,
        creditInPortfolio:
          Math.round((+client.creditInPortfolio - +payload) * 100) / 100,
      };
    },
    showDrawer(state) {
      state.drawer = true;
    },
    hideDrawer(state) {
      state.drawer = false;
    },
  },
  actions: {
    moreOneProduct({ commit, state }, payload) {
      const index = getProductIndex(state.products, payload);
      commit("moreOneProduct", index);
    },
    minusOneProduct({ commit, state }, payload) {
      if (state.request && payload.amount === 0) {
        return;
      }

      if (!state.request && payload.amount === 1) {
        commit("delProduct", payload);
        return;
      }
      const index = getProductIndex(state.products, payload);
      if (index > -1) {
        if (+payload.amountDev) {
          for (let i = 0; i < payload.amountDev; i++) {
            commit("minusOneProduct", index);
          }
        } else {
          commit("minusOneProduct", index);
        }
      }

      if (state.request) {
        const { product, id, price } = payload;
        let obj = {
          id,
          product,
          amount: payload.amountDev || 1,
          price,
          date: new Date(),
        };
        commit("addDevolution", obj);
      }
    },
    delProduct({ commit, state }, payload) {
      const index = getProductIndex(state.products, payload);
      commit("delProduct", index);
    },
    clear({ commit }) {
      commit("delClient");
      commit("delSeller");
      commit("clearProducts");
      commit("removeRequest");
    },
    setRequest({ commit }, payload) {
      axios(`/request/${payload}`).then(({ data }) => {
        const { client, products, salesman } = data;
        commit("setRequest", data);
        products.forEach((i) =>
          commit("addProduct", { ...i, price: i.unitary_value })
        );
        commit("addClient", client);
        commit("addSeller", salesman);
      });
    },
    async loadBox({ commit, state }) {
      if (state.box) return;
      let resp = await getBox();
      if (resp.status == 204) {
        commit("snackbar/show", {
          message: "Não existe Caixa aberto!",
          type: "error",
        });
        router.push({ name: "OpenBox" });
      } else {
        commit("setBox", resp.data.box);
      }
    },
  },
  getters: {
    products(state) {
      return state.products || [];
    },
    total(state) {
      const totals = state.products.map((o) => o.amount * o.price) || [];
      return totals.reduce((a, c) => a + c, 0) || 0;
    },
    client(state) {
      return state.client;
    },
    seller(state) {
      return state.seller;
    },
    request(state) {
      return state.request;
    },
    devolutionProducts(state) {
      return state.devolutionProducts;
    },
    box(state) {
      return state.box;
    },
    checkBox(state) {
      return state.box !== null;
    },
    drawer(state) {
      return state.drawer;
    },
  },
};

// Helpers

function getProductIndex(arr = [], item) {
  let index = -1;
  arr.forEach((p, i) => {
    if (
      p.product.id === item.product.id &&
      p.amount === item.amount &&
      p.price === item.price
    ) {
      index = i;
    }
  });
  return index;
}
