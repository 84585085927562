import {getCarriers} from '../../loader'

export default {
  namespaced: true,
  state: {
    carriers: [],
  },
  mutations: {
    setCarriers(state, payload) {
      state.carriers = payload;
    }
  },
  actions: {
    async loadCarriers({commit}) {
      let resp = await getCarriers();
      commit('setCarriers', resp.data)
    }
  },
  getters: {
    carriers(state) {
      return state.carriers;
    },
  },
};
