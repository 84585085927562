import axios from './axios';

export const getClients = async function() {
    return await axios('/clients')
}

export const getSellers = async function() {
    return await axios('/sellers')
}

export const getBox = async function () {
    return await axios('/box/opened')
}

export const getCoins = async function() {
    return await axios("/payment-types")
}

export const getCarriers = async function() {
    return await axios("/carriers")
}

export const getProducts = async function() {
    return await axios("/products")
}

