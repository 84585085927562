import { getSellers } from "../../loader";

export default {
  namespaced: true,
  state: {
    sellers: [],
  },
  mutations: {
    setSellers(state, payload) {
      state.sellers = payload;
    }
  },
  actions: {
    async loadSellers({commit}) {
      let resp = await getSellers();
      commit('setSellers', resp.data);
    },
  },
  getters: {
    sellers(state) {
      return state.sellers;
    },
  },
};
