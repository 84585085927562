import { getProducts } from "../../loader";

export default {
  namespaced: true,
  state: {
    products: [],
  },
  mutations: {
    setProducts(state, payload) {
      state.products = payload;
    }
  },
  actions: {
    async loadProducts({commit}) {
      let resp = await getProducts();
      commit('setProducts', resp.data);
    },
  },
  getters: {
    products(state) {
      return state.products;
    },
  },
};
