import Vue from "vue";
import VueRouter from "vue-router";

// import Home from "../views/Home.vue";
import App from "../views/_templates/App";

import MasterReport from "../views/reports/MasterReport";

import { authGuard } from "../auth.guard";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login/:token",
    component: () => import("../views/Login.vue"),
  },
  {
    path: "/login",
    name: "Login",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Login.vue"),
  },
  {
    path: "/app",
    component: App,
    children: [
      {
        path: "",
        name: "Home",
        redirect: "pdv",
      },
      {
        path: "pdv/:token?",
        name: "PDV",
        component: () => import("../views/PDV/Pdv.vue"),
        beforeEnter: authGuard,
      },
      {
        path: "open_box",
        name: "OpenBox",
        component: () => import("../views/OpenBox.vue"),
        beforeEnter: authGuard,
      },
      {
        path: "close_box",
        name: "CloseBox",
        component: () => import("../views/CloseBox.vue"),
        beforeEnter: authGuard,
      },
      {
        path: "withdraw_box",
        name: "WithdrawBox",
        component: () => import("../views/WithdrawBox.vue"),
        beforeEnter: authGuard,
      },
      {
        path: "reinforce_box",
        name: "ReinforceBox",
        component: () => import("../views/ReinforceBox.vue"),
        beforeEnter: authGuard,
      },
      {
        path: "payment",
        name: "Payment",
        component: () => import("../views/Payment.vue"),
        beforeEnter: authGuard,
      },
      {
        path: "reports",
        component: MasterReport,
        children: [
          {
            path: "salePayment/:id",
            name: "sale-payment",
            component: () => import("../views/reports/SalePayment.vue"),
            beforeEnter: authGuard,
          },
          {
            path: "salePaymentRequest/:request_id",
            name: "sale-payment-request",
            component: () => import("../views/reports/SalePayment.vue"),
            beforeEnter: authGuard,
          },
          {
            path: "request/:id",
            name: "request",
            component: () => import("../views/reports/Request.vue"),
            beforeEnter: authGuard,
          },
          {
            path: "openbox",
            name: "report_openbox",
            component: () => import("../views/reports/OpenBox.vue"),
            beforeEnter: authGuard,
          },
          {
            path: "closebox",
            name: "report_closebox",
            component: () => import("../views/reports/CloseBox.vue"),
            beforeEnter: authGuard,
          },
          {
            path: "reinforce",
            name: "report_reinforce",
            component: () => import("../views/reports/Reinforce.vue"),
            beforeEnter: authGuard,
          },
          {
            path: "withdraw",
            name: "report_withdraw",
            component: () => import("../views/reports/Withdraw.vue"),
            beforeEnter: authGuard,
          },
        ],
      },
    ],
  },
  {
    path: "**",
    name: "error",
    redirect: "/app/pdv",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
