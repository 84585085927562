export default {
  namespaced: true,
  state: {
    snackbar: false,
    message: null,
    timeout: 5000,
    type: "info",
  },
  mutations: {
    show(state, payload) {
      state.snackbar = true;
      state.message = payload.message;
      state.timeout = payload.timeout || 5000;
      state.type = payload.type || "info";
    },
    hide(state) {
      state.snackbar = false;
    },
  },
  actions: {
    error({ commit }, payload) {
      if (typeof payload === "string") payload = { message: payload };
      payload.type = "error";
      commit("show", payload);
    },
    success({ commit }, payload) {
      if (typeof payload === "string") payload = { message: payload };
      payload.type = "success";
      commit("show", payload);
    },
    info({ commit }, payload) {
      if (typeof payload === "string") payload = { message: payload };
      commit("show", payload);
    },
    warning({ commit }, payload) {
      if (typeof payload === "string") payload = { message: payload };
      payload.type = "warning";
      commit("show", payload);
    },
    hide({ commit }) {
      commit("hide");
    },
  },
  getters: {
    message: (state) => state.message,
    type: (state) => state.type,
    timeout: (state) => state.timeout,
    snackbar: (state) => state.snackbar,
  },
};
