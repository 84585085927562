<template>
  <!-- App.vue -->

  <div class="d-flex flex-fill fill-height">
    <SnackBar />
    <v-app-bar app>
      <v-app-bar-nav-icon @click="toogleDrawer"></v-app-bar-nav-icon>

      <v-toolbar-title>Fisio Power - PDV</v-toolbar-title>

      <v-spacer></v-spacer>

      <v-menu left bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list width="150">
          <v-list-item v-show="false" link>
            <v-list-item-icon>
              <v-icon>mdi-card-account-details-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title @click="() => {}">Perfil</v-list-item-title>
          </v-list-item>

          <v-divider v-show="false" />

          <v-list-item @click="logout">
            <v-list-item-icon>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Sair</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-navigation-drawer app expand-on-hover :value="drawer">
      <v-list nav dense>
        <v-list-item-group
          v-model="group"
          active-class="deep-purple--text text--accent-4"
        >
          <v-list-item v-show="false" to="/app" exact>
            <v-list-item-icon>
              <v-icon>mdi-home</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Home</v-list-item-title>
          </v-list-item>

          <v-list-item to="/app/pdv">
            <v-list-item-icon>
              <v-icon>mdi-cash-multiple</v-icon>
            </v-list-item-icon>
            <v-list-item-title>PDV</v-list-item-title>
          </v-list-item>

          <v-list-item to="/app/open_box">
            <v-list-item-icon>
              <v-icon color="green">mdi-package-variant</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Abrir Caixa</v-list-item-title>
          </v-list-item>

          <v-list-item to="/app/close_box">
            <v-list-item-icon>
              <v-icon color="red">mdi-package-variant-closed</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Fechar Caixa</v-list-item-title>
          </v-list-item>

          <v-list-item to="/app/reinforce_box">
            <v-list-item-icon>
              <v-icon color="green">mdi-cash-plus</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Reforço</v-list-item-title>
          </v-list-item>

          <v-list-item to="/app/withdraw_box">
            <v-list-item-icon>
              <v-icon color="red">mdi-water</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Sangria</v-list-item-title>
          </v-list-item>

          <v-divider></v-divider>

          <v-list-item :href="control" target="_blank">
            <v-list-item-icon>
              <v-icon color="deep-purple">mdi-monitor-dashboard</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Painel de Controle</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <!-- Sizes your content based upon application components -->
    <v-main :class="bg_random">
      <!-- Provides the application the proper gutter -->
      <v-container fluid class="fill-height">
        <!-- If using vue-router -->
        <transition name="slide" mode="out-in">
          <router-view></router-view>
        </transition>
      </v-container>
    </v-main>

    <v-footer app class="white justify-center">
      <a
        target="_blank"
        class="text-decoration-none"
        href="//www.suporteinformatika.com.br"
      >
        <span class="grey--text text--darken-1">Suporte Informatika</span>
      </a>
    </v-footer>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import SnackBar from "./../../components/SnackBar";

import { config } from "../../enviroment";

export default {
  name: "App",
  components: { SnackBar },
  data: () => ({
    group: null,
  }),
  methods: {
    ...mapMutations(["hideDrawer", "showDrawer"]),
    toogleDrawer() {
      this.drawer ? this.hideDrawer() : this.showDrawer();
    },
  },
  computed: {
    ...mapGetters(["drawer"]),
    bg_random: () => {
      let index = Math.floor(Math.random() * 4);
      return `bg_${index > 0 ? index : ++index}`;
    },
    control: () => config.BASE_URL,
  },
};
</script>

<style lang="scss">
.bg-default {
  background-color: #f2f2f2;
}
.bg_1 {
  background-size: cover;
  background-image: url("./../../assets/bg.jpg");
}
.bg_2 {
  background-size: cover;
  background-image: url("./../../assets/bg_2.jpg");
}
.bg_3 {
  background-size: cover;
  background-image: url("./../../assets/bg_3.jpg");
}
.bg_login {
  background-size: cover;
  background-image: url("./../../assets/bg-login.jpg");
}
@keyframes slide-in {
  from {
    transform: translateY(-30px);
    opacity: 0;
  }
  80% {
    transform: translateY(10px);
    opacity: 1;
  }
  to {
    transform: translateY(0px);
  }
}
@keyframes slide-out {
  from {
    transform: translateY(0px);
    opacity: 1;
  }
  20% {
    transform: translateY(10px);
  }
  to {
    transform: translateY(-30px);
    opacity: 0;
  }
}
.slide-enter-active {
  animation: slide-in 0.3s ease;
}
.slide-leave-active {
  animation: slide-out 0.3s ease;
}
</style>
