import { getCoins } from "../../loader";

export default {
  namespaced: true,
  state: {
    coins: [],
  },
  mutations: {
    setCoins(state, payload) {
      state.coins = payload;
    },
  },
  actions: {
    async loadCoins({commit}) {
      let resp = await getCoins();
      commit('setCoins', resp.data);
    },
  },
  getters: {
    coins(state) {
      return state.coins;
    },
  },
};
