import axios from "./axios"

export const authGuard = async (to, from, next) => {
  if (to.params.token) {
    localStorage.setItem("token", to.params.token);
    if (await checkToken()) return next({name: 'PDV'});
  }
  if (to.name !== "Login" && !await checkToken()) next({ name: "Login" });
  next();
};

async function checkToken() {
  const {status} = await axios.post('/check_token')
  return status === 202 ? true : false;
}