<template>
  <div
    class="d-flex flex-fill fill-height justify-center section-to-print target"
  >
    <router-view></router-view>
    <v-btn
      color="info"
      absolute
      fab
      class="d-print-none btn-print"
      @click="print"
    >
      <v-icon>mdi-printer-pos</v-icon>
    </v-btn>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "MasterReport",
  methods: {
    ...mapMutations(["showDrawer", "hideDrawer"]),
    async print() {
      await this.hideDrawer();
      await window.print();
      await this.showDrawer();
    },
  },
  computed: {
    ...mapGetters(["drawer"]),
  },
};
</script>

<style scoped>
.target {
  position: relative;
}
.btn-print {
  right: 10px;
  bottom: 10px;
}
</style>
